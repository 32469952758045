import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ItemSelected } from "../../HOC";
import {
  Page,
  TopNav,
  ConfirmSection,
  SubscriptionBanner,
  Service,
} from "../../shared/components";

import { CheckableButton } from "../../shared/components/CheckableButton";
import { useNavigate } from "react-router-dom";
import { PWA_ROUTES } from "../../constants/appConstants";
import { SubscriptionDetails } from "../../shared/components/SubscriptionDetails";

import { initialize } from "../../utils/redux/actions/network.actions";
import { profile as profileAction } from "../../utils/redux/actions";
import {
  clearSubscription,
  selectCharge,
} from "../../utils/redux/actions/subscription.action";
import { StyledPaymentMethods } from "../../shared/components/PaymentMethods/PaymentMethods.styles";
import loader from "../../assets/images/loader.gif";
import styled from "styled-components";
import { CheckoutScreen as events } from "../../analytics/events";
import { useSearchParams } from "react-router-dom";
import { SELECTED_SOURCE } from "../../appConstants";

const SUBSCRIPTION = "subscription";


const PaymentsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { language } = useSelector((state) => state);
  const {network} = useSelector((state) => state);

  const [searchParams] = useSearchParams();

  const [paymentMethodIndex, setPaymentMethodIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pMethod, setPmethod] = useState(false);

  const [pOtionNoMomo, setOptionNoMomo] = useState([]);

  const {
    selectedItem,
    selectedCharge,
    paymentMethods,
    paymentStatusInfo,
    error,
  } = useSelector((state) => state?.subscriptions);

  const service = selectedItem?.service;

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      let arr = paymentMethods.filter((e) => e?.balanceType !== "Momo");
      setOptionNoMomo(arr);
      setPmethod(true);
    }

    setLoading(false);
  }, [paymentMethods]);

  useEffect(() => {
    if (error && error != "") {
      dispatch(clearSubscription());
      navigate(PWA_ROUTES.GENERIC_ERROR);
    }
  }, [error]);

  useEffect(() => {
    if (paymentStatusInfo == null) return;

    if (paymentStatusInfo?.Message?.statusCode !== "") {
      navigate(PWA_ROUTES.TRANSACTION_RES);
    } else {
      dispatch(clearSubscription());
      navigate(PWA_ROUTES.GENERIC_ERROR);
    }
  }, [paymentStatusInfo]);

  useEffect(() => {
    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.payment_view_screen(
        network?.call?.headers?.isoRegion,
        network?.call?.headers.environment
      ),
    });

    if (paymentMethods?.length > 0 && searchParams.get("code")) {
      const retrievedState = searchParams.get("state");
      if (retrievedState) {
        const parsedProfile = JSON.parse(retrievedState);

        dispatch(initialize(parsedProfile));
        dispatch(profileAction.updateProfile(parsedProfile));
        Authorize(searchParams.get("code"), parsedProfile);
      }
    }

    if (paymentMethods?.length > 0 && searchParams.get("error")) {
      const retrievedState = searchParams.get("state");
      if (retrievedState) {
        const parsedProfile = JSON.parse(retrievedState);
        dispatch(initialize(parsedProfile));
        dispatch(profileAction.updateProfile(parsedProfile));
        navigate(PWA_ROUTES.CONTENT_INFO);
      }
    }
  }, []);


	const Authorize = () => {
    navigate(PWA_ROUTES.CONSENT);
  };


  const ModalTextComponent = () => {
    return (
      <>
        <div className="modal_text">
          {selectedItem?.subscriptionType == "once off" ? (
            <p>{`R${selectedItem?.price} will be deducted from your Airtime.`}</p>
          ) : (
            <p>{`${language?.chosenPack?.language?.COPY?.SCREENS?.payment?.charge_msg1} ${selectedCharge?.currency} ${selectedCharge?.price} ${language?.chosenPack?.language?.COPY?.SCREENS?.payment?.charge_msg2} ${selectedCharge?.value} ${language?.chosenPack?.language?.COPY?.SCREENS?.payment?.charge_msg3}.`}</p>
          )}
          <p>
            {
              language?.chosenPack?.language?.COPY?.SCREENS?.payment
                ?.charge_msg4
            }
            .
          </p>
          <div style={{ marginTop: 45 }}>
            <p>
              {
                language?.chosenPack?.language?.COPY?.SCREENS?.payment
                  ?.subscribe_msg1
              }{" "}
              <span
                onClick={() => {
                  navigate(PWA_ROUTES.TERMS_AND_CONDITIONS);
                }}
                style={{ color: "#FFCB05" }}>
                {" "}
                {
                  language?.chosenPack?.language?.COPY?.SCREENS?.payment
                    ?.subscribe_msg2
                }
              </span>{" "}
              {
                language?.chosenPack?.language?.COPY?.SCREENS?.payment
                  ?.subscribe_msg3
              }{" "}
              <span
                onClick={() => {
                  navigate(PWA_ROUTES.PRIVACY_NOTICE);
                }}
                style={{ color: "#FFCB05" }}>
                {
                  language?.chosenPack?.language?.COPY?.SCREENS?.payment
                    ?.subscribe_msg4
                }
              </span>{" "}
              {
                language?.chosenPack?.language?.COPY?.SCREENS?.payment
                  ?.subscribe_msg5
              }
              .
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <ItemSelected restrictFree={true}>
      <Page isBottomBar={false}>
        <TopNav
          title={
            selectedItem?.selectedSource === SELECTED_SOURCE.CONTENT
              ? selectedItem?.title
              : selectedItem?.name
          }
          enableSearch={false}
          showCloseIcon={true}
        />
        {selectedItem?.selectedSource === SELECTED_SOURCE.CONTENT ? (
          <>
            <SubscriptionBanner
              paymentStyle={true}
              imageUrl={selectedItem?.imageUrl}
            />
            <div
              style={{ display: "flex", alignSelf: "center", marginTop: -10 }}>
              <Service
                id={service?.id}
                logo={service?.logo}
                name={service?.name}
                description={service?.description}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 15,
              paddingBottom: 15,
              paddingRight: 30,
              paddingLeft: 30,
            }}>
            <div
              style={{
                backgroundImage: `url(${selectedItem?.logo})`,
                height: 112,
                width: 112,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
            <div>
              <p style={{ opacity: 0.6, marginTop: 10, textAlign:"center" }}>
                {selectedItem?.description}
              </p>
            </div>
          </div>
        )}

        <div>
          {selectedItem?.subscribed !== "Pending" && (
            <div style={{ paddingLeft: 25, paddingRight: 25 }}>
              <SubscriptionDetails />
            </div>
          )}
        </div>

        {pMethod && (
          <StyledPaymentMethods>
            <OptionDescr>{language?.chosenPack?.language?.COPY?.SCREENS?.payment?.selected_payment}</OptionDescr>
            
            {}
            {pOtionNoMomo?.length ? (
              pOtionNoMomo?.map((item, index) => {
                return (
                  <CheckableButton
                    isActive={item == "Airtime"}
                    selected={paymentMethodIndex == index}
                    body={item?.balanceType}
                    clickHandler={() => {
                      dispatch(selectCharge(item));
                      setPaymentMethodIndex(index);
                    }}
                    key={item?.balanceDetail?.activeValue}
                  />
                );
              })
            ) : (
              <Loader>
                <img src={loader} alt="" width="60" height="60" />
              </Loader>
            )}
            {/* {
            		selectedCharge?.value !== "once-off" &&
                    <div className="actions_second">
                    	{renewOption()}
                    </div>
            	}                        */}
            <ModalTextComponent />

            <ConfirmSection
              confirmHandler={() => {
                Authorize();
              }}
              buttonCaption={
                selectedItem?.content?.subscriptionType === SUBSCRIPTION
                  ? language?.chosenPack?.language?.COPY?.SCREENS?.payment?.sub
                  : language?.chosenPack?.language?.COPY?.SCREENS?.payment?.confirm_sub
              }
              loading={loading}
            />
          </StyledPaymentMethods>
        )}
      </Page>
    </ItemSelected>
  );
};

const OptionDescr = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 0px;
  margin-bottom: 12px;
`;

const Loader = styled.div`
  display: flex;
  padding: 14px;
  justify-content: center;
`;

export default PaymentsScreen;
