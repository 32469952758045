

export const getAyoba = ()=> {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;


	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {

		return null;
	}

	if (/android/i.test(userAgent)) {
		try {

			return Android;
		} catch (error) {

			return null;
		}
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return null; // todo 
	}

	return null;
};

export const onLocationChanged = (lat, lon) =>{
	try {
		localStorage.setItem("geo", {lon:`${lon?lon:"-1"}`, lat:`${lat?lat:"-1"}`});
	} catch (error) {
		console.log(error);
	}
};


export const finish = ()=>{
};