import {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import { PWA_ROUTES } from "../../constants/appConstants";
import { SubscriptionBanner, Page, TopNav, SubscriptionSuccessInfo } from "../../shared/components";
import {clearSubscription } from "../../utils/redux/actions/subscription.action";
import {CheckoutScreen as events} from "../../analytics/events";
import { SubScriptionStatePopUpScreen } from "../SubScriptionStatePopUpScreen";
import { SELECTED_SOURCE } from "../../appConstants";

const TransactionResScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

 const { language } = useSelector((state) => state);
  const {
    selectedCharge,
    selectedItem,
    isConfirmed,
    paymentStatusInfo,
  } = useSelector((state) => state?.subscriptions);

  const { network } = useSelector((state) => state);

  const [alreadySubscribed, setAlreadySubscribed] = useState(false);
  const [insufficientFund, setInsufficientFund] = useState(false);
  const [offerNotExist, setOfferNotExist] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(true);


  useEffect(() => {
    if (isSuccessful) {
      const analytItem = localStorage.getItem("set_analytics");
      localStorage.setItem("set_analytics", true);
      if (!analytItem !== null && !analytItem !== undefined) {
        dispatch({
          type: "ANALYTICS/TRACK_EVENT",
          event: events.checkout_view_screen_success(
            network?.call?.headers?.isoRegion,
            network?.call?.headers.environment,
            selectedCharge?.price,
            selectedItem?.ID?.id,
            selectedItem?.title,
            selectedItem?.vendor?.title,
          ),
        });
      }
    }
  }, [isSuccessful]);


  useEffect(() => {
    if (paymentStatusInfo?.Message?.statusCode === "2084") {
      setAlreadySubscribed(true);

      setIsSuccessful(false);
      setInsufficientFund(false);
      // setSubscribedResponse(false);
      if (alreadySubscribed) {
        dispatch({
          type: "ANALYTICS/TRACK_EVENT",
          event: events.checkout_view_screen_already(
            network?.call?.headers?.isoRegion,
            network?.call?.headers.environment,
            selectedCharge?.price,
            selectedItem?.ID?.id,
            selectedItem?.title,
          ),
        });
      }
      return;
    }

    if (paymentStatusInfo?.Message?.statusCode === "1012") {
      setAlreadySubscribed(false);
      setIsSuccessful(false);
      setInsufficientFund(false);
      setOfferNotExist(true);

      return;
    }

    if (paymentStatusInfo?.Message?.statusCode == "2032") {
      setInsufficientFund(true);

      setIsSuccessful(false);
      setAlreadySubscribed(false);
      if (insufficientFund) {
        dispatch({
          type: "ANALYTICS/TRACK_EVENT",
          event: events.checkout_view_screen_insufficient(
            network?.call?.headers?.isoRegion,
            network?.call?.headers.environment,
            selectedCharge?.price,
            selectedItem?.ID?.id,
            selectedItem?.title,
          ),
        });
      }
      return;
    }

    if (paymentStatusInfo?.Message?.statusCode === "0000") {
      setIsSuccessful(true);
      setAlreadySubscribed(false);
      setInsufficientFund(false);

      return;
    }

    if (
      paymentStatusInfo?.Message?.statusCode !== "0000" ||
      paymentStatusInfo?.Message?.statusCode !== "2084" ||
      paymentStatusInfo?.Message?.statusCode !== "2032" ||
      paymentStatusInfo?.Message?.statusCode !== "1012"
    ) {
      dispatch(clearSubscription());
      navigate(PWA_ROUTES.GENERIC_ERROR);
    }
  }, [paymentStatusInfo, isConfirmed]);


  const SubStatusComponent = () => {
    
    let title = language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_success;
    let info1 =language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_success_sub;
    let info2 =
    language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_success_sub_two;

    if (alreadySubscribed) {
      title =  language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_title_already;

      info1 = language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_title_already_sub;
      info2 = "";
    } else if (insufficientFund) {
      title = language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_title_insufficient;
      info1 = language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_title_insufficient_sub;
      info2 = "";
    } else if (offerNotExist) {
      title = language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_title_offers_not_found;
      info1 = language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_title_offers_not_found_sub;
      info2 = "";
    } else if (isSuccessful) {
      title = language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_success;
      info1 = language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_success_sub;
      info2 =
      language?.chosenPack?.language?.COPY?.SCREENS?.transact_response?.response_success_sub_two;
    }


    return (
      <>
        <TopNav
          title={
            selectedItem?.selectedSource === SELECTED_SOURCE.CONTENT
              ? selectedItem?.title
              : selectedItem?.name
          }
          enableSearch={false}
          showCloseIcon={false}
        />
        {selectedItem?.selectedSource === SELECTED_SOURCE.CONTENT ? (
          <SubscriptionBanner
            paymentStyle={true}
            imageUrl={selectedItem?.imageUrl}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 15,
              paddingBottom: 15,
              paddingRight: 30,
              paddingLeft: 30,
            }}>
            <div
              style={{
                backgroundImage: `url(${selectedItem?.logo})`,
                height: 112,
                width: 112,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
          </div>
        )}
        <SubscriptionSuccessInfo info={title} info1={info1} info2={info2} />
      </>
    );
  };

  return (
    <Page isBottomBar={false}>
      <SubScriptionStatePopUpScreen />
      <SubStatusComponent />
    </Page>
  );
};


export default TransactionResScreen;
