// import { useEffect, useState } from "react";
import { useEffect } from "react";

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  // useNavigate,
} from "react-router-dom";
import { PWA_ROUTES, COUNTRIES } from "./constants/appConstants";
import { useSelector, useDispatch } from "react-redux";
import { initialize } from "./utils/redux/actions/network.actions";
import {
  profile as profileAction,
  // homeBanners as homeBannersAction,
} from "./utils/redux/actions";
import { onInstalledPWA } from "./utils/redux/actions/appState.actions";
import { useAuth0 } from "@auth0/auth0-react";
import { getAyoba } from "./lib/microapp";
// import { ModalButtons, ModalText, Modal } from "./shared/components";
// import { AuthenticationScreen as events } from "./analytics/events";
import InitialiseNotification from "./utils/notificationUtil";

import {
  SeriesContentScreen,
  ContentInfoScreen,
  ServiceDetailsScreen,
  HomeScreen,
  ServiceScreen,
  MyPlayScreen,
  PaymentsScreen,
  TopUpScreen,
  SubscriptionFailScreen,
  SubscriptionSuccessScreen,
  SubscriptionScreen,
  ActiveSubscriptionScreen,
  CategoriesScreen,
  ManageScreen,
  ProfileScreen,
  InactiveSubscriptionScreen,
  PurchaseScreen,
  HelpScreen,
  TermsAndConditionsScreen,
  ContentPlayer,
  TransactionResScreen,
  GenericErrorScreen,
  Login,
  Logout,
  Otp,
  ProtectedScreen,
  PendingSubscriptionScreen,
  AuthRedirect,
  LoginRedirect,
  PlayPlayer,
  PlayGroundScreen,
  ComingSoonScreen,
  PrivacyNoticesScreen,
  PrivacyNoticeView,
} from "./screens";
import { SearchScreen } from "./screens/SearchScreen";
import HelperFunctions from "./utils/HelperFunctions";
import { ConsentScreen } from "./screens/ConsentScreen";
import { LanguagesScreen } from "./screens/LanguagesScreen";
import { setAnalyticsAppId } from "./analytics/tracker";

//Production_release_1_01_11_2023
function App() {
  const { profile, network } = useSelector((state) => state);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { user: Auth0User, isAuthenticated } = useAuth0();
  // const { user: Auth0User, logout, isAuthenticated } = useAuth0();

  // const [showModal, setShowModal] = useState(false);
  const HOST = window.location.host;

  const Ayoba = getAyoba();
  useEffect(() => {
    if (window.navigator.standalone === true) {
      dispatch(onInstalledPWA());
    }
    microAppCheck(true); //skip Ayoba check
        
    if (Ayoba != null && Ayoba != undefined) {
      console.log("we are inside ayoba");
      console.log("Ayoba msisdn", Ayoba?.getMsisdn()?.toString());
      console.log("Ayoba country/region", Ayoba?.getCountry());
    }
  }, []);

  window.addEventListener("appinstalled", () => {
    dispatch(onInstalledPWA());
  });

  // useEffect(() => {
  //   if (getAyoba() != null && getAyoba() != undefined) {
  //     microAppCheck(true); //skip Ayoba checkN
  //     return;
  //   }

  //   if (profile.data) {
  //     // fetch(
  //     //   "https://ipapi.co/json/?key=D32Wf1IcUm2FR7XkhtJ2sCxNvTT6SYx0NqL6lkzOdFzUuFUphh",
  //     // )
  //     fetch(
  //       "https://ipapi.co/json/",
  //     )
  //       .then((data) => {
  //         return data.json();
  //       })
  //       .then((res) => {
  //         setAnalyticsAppId(res?.country_code_iso3);
  //         dispatch(
  //           initialize({
  //             msisdn: `${profile.data?.msisdn}`,
  //             region: profile.data?.region,
  //             isoRegion: res?.country_code_iso3,
  //           }),
  //         );
  //       });
  //   }
  // }, [profile]);

  useEffect(() => {
    /*
     * If profile exists, don't check region using ipapi or query params
     */
    // if (profile.data && getAyoba() == null) return;

    // fetch(
    //   "https://ipapi.co/json/?key=D32Wf1IcUm2FR7XkhtJ2sCxNvTT6SYx0NqL6lkzOdFzUuFUphh",
    // )
    fetch(
      "https://ipapi.co/json/",
    )
      .then((data) => {
        return data.json();
      })
      .then((res) => {
        setAnalyticsAppId(res?.country_code_iso3);
        const { r } = HelperFunctions.queryParamsToMap(window.location.search);
        const _r = sessionStorage.getItem("r") || r;
        const queryParamRegion = Object.values(COUNTRIES).filter(
          (c) => c.iso3.toLowerCase() === _r?.toLowerCase(),
        )[0];

        if (!_r && res?.country_code) {
          const ipapiRegion = Object.values(COUNTRIES).filter(
            (c) =>
              c.iso3.toLowerCase() === res?.country_code_iso3?.toLowerCase(),
          )[0];

          if (ipapiRegion) {
            // Perform redirect if res?.country_code is found in list, query param is null and redirect url exists
            if (ipapiRegion.redirect) {
              window.location.href = ipapiRegion.url;
            }
          } else {
            // Perform redirect to coming soon page if res?.country_code is not found in list and query param is null
            if (window.location.pathname === PWA_ROUTES.COMING_SOON) return;
            history.pushState(null, null, PWA_ROUTES.COMING_SOON);
            history.go();
          }
        }
        if (_r) {
          sessionStorage.setItem("r", _r);
        }
        const currentRegion = queryParamRegion
          ? queryParamRegion?.iso3
          : res?.country_code_iso3;

        // switch (currentRegion.toLowerCase()) {
        //   case "gha":
        //     dispatch(homeBannersAction.setGhanaBanners());
        //     break;
        //   case "zmb":
        //     dispatch(homeBannersAction.setZambiaBanners());
        //     break;
        //   case "cmr":
        //     dispatch(homeBannersAction.setCameroonBanners());
        //     break;
        //   case "cog":
        //     dispatch(homeBannersAction.setCongoBanners());
        //     break;
        //   case "civ":
        //     dispatch(homeBannersAction.setCivBanners());
        //     break;
        //   case "swz":
        //     dispatch(homeBannersAction.setEswatiniBanners());
        //     break;
        //   case "uga":
        //     dispatch(homeBannersAction.setUgandaBanners());
        //     break;
        //   default:
        //     dispatch(homeBannersAction.setPlaceholderBanners());
        // }

        dispatch(
          initialize({
            msisdn: `${Auth0User?.name}`,
            region: currentRegion,
            isoRegion: res?.country_code_iso3,
            // environment: "UAT",
            environment: HOST.includes("sit")
              ? "SIT"
              : HOST.includes("uat")
              ? "UAT"
              : HOST.includes("dev")
              ? "DEV"
              : HOST.includes("localhost")
              ? "DEV"
              : "PROD",
          }),
        );

        if (Auth0User == undefined || Auth0User == null) return;

        let user = {
          ...Auth0User,
        };

        /*
         * NOTE: if MSISDN is from KYC
         */
        if (Auth0User["https://mymtn.com/userMetaData"]?.kyc?.phoneNumber) {
          user = {
            ...user,
            name: Auth0User["https://mymtn.com/userMetaData"]?.kyc?.phoneNumber,
          };
        }

        const userMetaData = user["https://mymtn.com/userMetaData"]?.kyc;

        if (userMetaData == undefined || userMetaData == null) return;

        if (user?.name && network?.call?.subscribePush != undefined) {
          try {
            InitialiseNotification(
              `${user?.name}`.replace("+", ""),
              network?.call,
            );
          } catch {
            console.log("could not init notifications");
          }
        }

        // if(profile?.data){
        dispatch(
          initialize({
            msisdn: `${user?.name}`,
            region: currentRegion,
            isoRegion: res?.country_code_iso3,
          }),
        );
        // }

        dispatch(
          profileAction.updateProfile({
            msisdn: user?.name,
            birthday: userMetaData?.birthDate,
            name: userMetaData?.fullName,
            location: currentRegion,
            email: undefined,
            language: "English",
            region: currentRegion,
            isoRegion: res?.country_code_iso3,
          }),
        );
      });
  }, [Auth0User, isAuthenticated]);

  const isLoggedIn = () => {
    return profile.data !== null;
  };

  const microAppCheck = () => {
    // fetch(
    //   "https://ipapi.co/json/?key=D32Wf1IcUm2FR7XkhtJ2sCxNvTT6SYx0NqL6lkzOdFzUuFUphh",
    // )
    //   .then((data) => {
    //     return data.json();
    //   })
    //   .then((res) => {
    //     const regionNamesInEnglish = new Intl.DisplayNames(["en"], {
    //       type: "region",
    //     });
    //     const Ayoba = getAyoba();

    //     if (Ayoba == null) {
    //       return;
    //     }

    //     dispatch({
    //       type: "ANALYTICS/TRACK_EVENT",
    //       event: events.login_authentication(
    //         network?.call?.headers?.isoRegion,
    //         network?.call?.headers?.region,
    //         network?.call?.headers?.environment,
    //         "ayoba",
    //       ),
    //     });

    //     if (skip) return; //skip Ayoba check

    //     if (isAuthenticated) {
    //       if (
    //         profile?.data?.msisdn?.toString() !== Ayoba?.getMsisdn()?.toString()
    //       ) {
    //         dispatch(
    //           initialize({
    //             msisdn: Ayoba?.getMsisdn(),
    //             region: regionNamesInEnglish.of(Ayoba?.getCountry()),
    //             isoRegion: res?.country_code_iso3,
    //           }),
    //         );
    //         setShowModal(true);
    //       }
    //     } else {
    //       if (
    //         profile?.data?.msisdn?.toString() !== Ayoba?.getMsisdn()?.toString()
    //       ) {
    //         dispatch(
    //           initialize({
    //             msisdn: Ayoba?.getMsisdn(),
    //             region: regionNamesInEnglish.of(Ayoba?.getCountry()),
    //             isoRegion: res?.country_code_iso3,
    //           }),
    //         );
    //         dispatch(
    //           profileAction.updateProfile({
    //             msisdn: Ayoba?.getMsisdn(),
    //             location: regionNamesInEnglish.of(Ayoba?.getCountry()),
    //             isoRegion: res?.country_code_iso3,
    //           }),
    //         );
    //       }
    //     }
    //   });
  };

  // useEffect(() => {
  //   if (showModal) {
  //     return (
  //       <div>
  //         <Modal onClose={() => {}} show={showModal} title={"Authentication"}>
  //           <ModalText
  //             firstSentence={"We are logging you out."}
  //             secondSentence={`Ayoba has picked up ${profile?.data?.msisdn}.`}
  //             thirdSentence={`You will be logged out. Please log in with this number ${Ayoba?.getMsisdn()}.`}
  //           />
  //           <ModalButtons
  //             okButtonText={"Ok"}
  //             onClose={() => {
  //               setShowModal(false);
  //               logout({ returnTo: `${window.location.origin}/logout` });
  //             }}
  //           />
  //         </Modal>
  //       </div>
  //     );
  //   }
  // }, [showModal]);

  // const InfoModal = () =>{
  // 	return <div>
  // 		<Modal
  // 			onClose={() => {}}
  // 			show={showModal}
  // 			title={"Authentication Error"}>
  // 			<ModalText
  // 				firstSentence="The number you have entered does not match your Ayoba account."
  // 				secondSentence="Please use your Ayoba phone number to continue."
  // 			/>
  // 			<ModalButtons
  // 				okButtonText={"Ok"}
  // 				onClose={() => {
  // 					setShowModal(false);
  // 					logout({returnTo:`${window.location.origin}/logout`});
  // 				}}

  // 			/>
  // 		</Modal>
  // 	</div>;
  // };

  return (
    <>
      {
        <BrowserRouter>
          <Routes>
            <Route exact path={PWA_ROUTES.LOGIN} element={<Login />} />
            <Route exact path={PWA_ROUTES.LOGOUT} element={<Logout />} />
            <Route
              exact
              path={PWA_ROUTES.AUTH_CALLBACK}
              element={<AuthRedirect />}
            />
            <Route
              exact
              path={PWA_ROUTES.LOGIN_REDIRECT}
              element={<LoginRedirect />}
            />

            <Route
              exact
              path={PWA_ROUTES.PRIVACY_NOTICE}
              element={<PrivacyNoticesScreen />}
            />

            <Route exact path={PWA_ROUTES.OTP} element={<Otp />} />
            <Route
              exact
              path={PWA_ROUTES.ROOT}
              element={
                <Navigate to={`${PWA_ROUTES.HOME}${window.location.search}`} />
              }
            />

            <Route
              exact
              path={PWA_ROUTES.PAYMENTS}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <PaymentsScreen />
                </ProtectedScreen>
              }
            />

            <Route
              exact
              path={PWA_ROUTES.CONTENT_INFO}
              element={<ContentInfoScreen />}
            />
            <Route
              exact
              path={PWA_ROUTES.SERVICE_DETAILS_SCREEN}
              element={<ServiceDetailsScreen />}
            />
            <Route
              exact
              path={PWA_ROUTES.SERIES}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <SeriesContentScreen />
                </ProtectedScreen>
              }
            />
            <Route
              exact
              path={PWA_ROUTES.SUBSCRIBE_SUCCESS}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <SubscriptionSuccessScreen />
                </ProtectedScreen>
              }
            />
            <Route
              exact
              path={PWA_ROUTES.SUBSCRIBE_FAIL}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <SubscriptionFailScreen />
                </ProtectedScreen>
              }
            />

            <Route
              exact
              path={PWA_ROUTES.SUBSCRIBE_CONFIRM}
              element={<MyPlayScreen />}
            />
            <Route
              exact
              path={PWA_ROUTES.COMING_SOON}
              element={<ComingSoonScreen />}
            />

            <Route
              exact
              path={PWA_ROUTES.CATEGORIES}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <CategoriesScreen />
                </ProtectedScreen>
              }
            />
            <Route exact path={PWA_ROUTES.MANAGE} element={<ManageScreen />} />
            <Route
              exact
              path={PWA_ROUTES.SUBSCRIPTIONS}
              element={
                <ProtectedScreen isLoggedIn={() => true}>
                  <SubscriptionScreen />
                </ProtectedScreen>
              }
            />
            <Route
              exact
              path={PWA_ROUTES.PURCHASES}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <PurchaseScreen />
                </ProtectedScreen>
              }
            />
            <Route
              exact
              path={PWA_ROUTES.ACTIVE_SUBSCRIPTIONS}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <ActiveSubscriptionScreen />
                </ProtectedScreen>
              }
            />
            <Route
              exact
              path={PWA_ROUTES.INACTIVE_SUBSCRIPTIONS}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <InactiveSubscriptionScreen />
                </ProtectedScreen>
              }
            />
            <Route
              exact
              path={PWA_ROUTES.PENDING_SUBSCRIPTIONS}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <PendingSubscriptionScreen />
                </ProtectedScreen>
              }
            />
            <Route
              exact
              path={PWA_ROUTES.FAQ}
              element={
                <ProtectedScreen isLoggedIn={() => true}>
                  <HelpScreen />
                </ProtectedScreen>
              }
            />
            <Route
              exact
              path={PWA_ROUTES.PROFILE}
              element={
                <ProtectedScreen isLoggedIn={() => true}>
                  <ProfileScreen />
                </ProtectedScreen>
              }
            />
            <Route
              exact
              path={PWA_ROUTES.TERMS_AND_CONDITIONS}
              element={
                <ProtectedScreen isLoggedIn={() => true}>
                  <TermsAndConditionsScreen />
                </ProtectedScreen>
              }
            />
            <Route
              exact
              path={PWA_ROUTES.CONTENT_PLAYER}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <ContentPlayer />
                </ProtectedScreen>
              }
            />
            <Route
              exact
              path={PWA_ROUTES.TRANSACTION_RES}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <TransactionResScreen />
                </ProtectedScreen>
              }
            />
            <Route
              path={`${PWA_ROUTES.TOPUP}/*`}
              element={
                <ProtectedScreen isLoggedIn={isLoggedIn}>
                  <TopUpScreen />
                </ProtectedScreen>
              }
            />
            <Route exact path={"/home"} element={<HomeScreen />} />

            <Route exact path={"/serviceScreen"} element={<ServiceScreen />} />
            <Route
              exact
              path={PWA_ROUTES.CONSENT}
              element={<ConsentScreen />}
            />

            <Route
              exact
              path={PWA_ROUTES.GENERIC_ERROR}
              element={<GenericErrorScreen />}
            />

            <Route
              exact
              path={PWA_ROUTES.TEST_AYOBA_MSISDN}
              element={<PlayGroundScreen />}
            />
            <Route exact path={PWA_ROUTES.SEARCH} element={<SearchScreen />} />
            <Route
              exact
              path={PWA_ROUTES.LANGUAGES}
              element={<LanguagesScreen />}
            />
            <Route
              exact
              path={PWA_ROUTES.PRIVACY_NOTICE_VIEW}
              element={<PrivacyNoticeView />}
            />
            <Route
              exact
              path={PWA_ROUTES.TEST_AYOBA_VIDEO}
              element={
                <PlayPlayer
                  deviceType={"android"}
                  source={"https://www.w3schools.com/html/mov_bbb.mp4"}
                  title={"Tom&Jerry"}
                  contentType={"video"}
                  imageUrl={
                    "https://images.ctfassets.net/kdawwlsweh27/2LtummpjO849eQ83yGGiUN/b33c73279163c84b65241cdfcc1c8844/Fresh_Stock_Content.jpg"
                  }
                  onClose={() => {}}
                />
              }
            />
          </Routes>
        </BrowserRouter>
      }
    </>
  );
}

export default App;
